import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import '../../components/Website/Website.css';
import './css/UserForm.css';
import { getUser, updateUser } from '../../services/requests';
import Layout from '../../components/Website/Layout';


const UserForm = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [user, setUser] = useState({
        title: '',
        first_name: '',
        last_name: '',
        email: '',
        card_number: ''
    });
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        if (id) {
            getUser(id)
                .then(response => {
                    setUser(response.user);
                })
                .catch(error => {
                    setErrorMessage('Failed to fetch user data');
                });
        }
    }, [id]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        updateUser(id, user)
            .then(() => {
                navigate('/admin/users?updated=true');
            })
            .catch(error => {
                setErrorMessage('Failed to update user');
            });
    };

    const handleCancelClick = () => {
        navigate('/admin/users')
    }

    return (
    <Layout>        
        <div className="container">        
              <h3 className="website__header">Edit user</h3>
              <p className="website__header-subtitle">Update the details of the user below.</p>

  {errorMessage ? 
    <>
    <div class="alert alert-danger" role="alert">
        {errorMessage}
    </div>
    </> : ''}
        
  <div class="row user-form__box">
    <div class="col-sm">

                <form onSubmit={handleSubmit}>

                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <label className="user-form__label">Title</label>
                            <input
                                className="form-control user-form__input"                        
                                type="text"
                                name="title"
                                value={user?.title}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="form-group">
                            <label className="user-form__label">First Name</label>
                            <input
                                className="form-control user-form__input"                        
                                type="text"
                                name="first_name"
                                value={user?.first_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="col-4">                    
                        <div className="form-group">
                            <label className="user-form__label">Last Name</label>
                            <input
                                className="form-control user-form__input"
                                type="text"
                                name="last_name"
                                value={user?.last_name}
                                onChange={handleChange}
                                required
                            />
                        </div>
                    </div>
                </div>

                    <div className="form-group">
                        <label className="user-form__label">Email</label>
                        <input
                            className="form-control user-form__input"
                            type="email"
                            name="email"
                            value={user?.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label className="user-form__label">Card Number</label>
                        <input 
                            className="form-control user-form__input"
                            type="text"
                            name="card_number"
                            value={user?.card_number}
                            onChange={handleChange}
                            required
                        />
                    </div>

                    <button type="submit" className="btn btn-primary user-form__save-button">Save</button>
                    <button type="button" onClick={() => handleCancelClick()} className="btn btn-secondary user-form__cancel-button">Cancel</button>                      
                </form>
            </div>
        </div>
    </div>
        </Layout>
    );
};

export default UserForm;
